/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    img: "img",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Big O Notation membantu mengukur kompleksitas kasus terburuk dari sebuah algoritma.\nDalam Big O notaion, n direpresentasikan sebagai jumlah masukan. Lantas kemudian muncul pertanyaan sebagai berikut:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Apa yang akan terjadi jika nilai n mendekati jumlah tak terhingga?."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://github.com/trekhleb/javascript-algorithms/raw/master/assets/big-o-graph.png",
    alt: "Big O Notaion"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/practicaldev/image/fetch/s--dvoLcb8F--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://cdn.hashnode.com/res/hashnode/image/upload/v1624724308842/5FcaIFvQ0.png",
    alt: "Big O Notaion"
  })), "\n", React.createElement(_components.h2, null, "Constant - O(1)"), "\n", React.createElement(_components.p, null, "soon..."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
