/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    img: "img",
    em: "em",
    code: "code",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Web developer atau yang terkhusus Fronend developer umumnya banyak menghabiskan waktu dibagian pemodelan atau styling (CSS). Dengan\npemanfaatan library yang tersedia, kita sekarang memiliki banyak cara untuk lebih cepat dan efektif\ndalam membangun web profile ataupun web aplikasi yang responsif."), "\n", React.createElement(_components.p, null, "Bagi Anda yang mencari librari CSS, di artikel ini kami merangkum library CSS yang bisa Anda gunakan dalam membangun projek Anda."), "\n", React.createElement(_components.h3, null, "Tailwindcss ", React.createElement(_components.a, {
    href: "https://tailwindcss.com/"
  }, "🚀")), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/practicaldev/image/fetch/s--QRUnljmI--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/mwfwpqtlohyncgc8jvwz.jpg",
    alt: "Tailwindcss"
  })), "\n", React.createElement(_components.p, null, "TailwindCSS merupakan salah satu library favorit saya yang banyak saya gunakan ketika bekerja dengan projek web.\nLibrary pada umumnya banyak kita dapat terdiri dari komponen pra-desain seperti: button, accordion, select dan sebagainya. Namun\nberbeda dengan TailwindCSS dimana kita tidak akan menemukan komponen-komponen seperti itu karena TailwindCSS lebih kesebuah ", React.createElement(_components.em, null, "Utilify first"), "\nseperti ", React.createElement(_components.code, null, "flex"), " untuk membuat layout, ", React.createElement(_components.code, null, "p-2"), " untuk padding, ", React.createElement(_components.code, null, "bg-gray-400"), " untuk background dengan warna gray dan sebagainya.\nKalian dapat mempelajarinya di ", React.createElement(_components.a, {
    href: "https://tailwindcss.com/"
  }, "web resminya"), "."), "\n", React.createElement(_components.h3, null, "Bulma ", React.createElement(_components.a, {
    href: "https://bulma.io/"
  }, "🚀")), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/practicaldev/image/fetch/s--o9RefRKR--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/6eaaay76931pwzgm060d.png",
    alt: "bulma"
  })), "\n", React.createElement(_components.p, null, "Bulma merupakan framework open source yang menyediakan banyak komponen yang siap untuk digunakan dan dapat dengan mudah dikombinasikan untuk\nmembangun web yang lebih responsiv."), "\n", React.createElement(_components.h3, null, "UI Kit ", React.createElement(_components.a, {
    href: "https://getuikit.com/"
  }, "🚀")), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/practicaldev/image/fetch/s--zfXj_QGr--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/s04d0dugsnkqbh1b6n88.png",
    alt: "uikit"
  })), "\n", React.createElement(_components.p, null, "UI Kit adalah salah satu framework frontend yang lebih ringan dan memiliki fitur modular untuk mengembangkan\nweb interface dengan cepat dan powerful."), "\n", React.createElement(_components.h3, null, "Primer CSS ", React.createElement(_components.a, {
    href: "https://primer.style/"
  }, "🚀")), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/practicaldev/image/fetch/s--FPTIf8im--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/il7e5494mm22ql7v5ci5.jpg",
    alt: "primecss"
  })), "\n", React.createElement(_components.p, null, "Diantara fitur yang ditawarkan Prime CSS adalah:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "animasi"), ", border, flexbox, color dan lain sebagainya"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Object"), ": gird, table"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Component"), ": button, avatar, breadcrumb, form, dropdown dan lain sebagainya"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
