/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import EditorPreview from "@/components/editor-preview";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    img: "img",
    ul: "ul",
    li: "li",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(EditorPreview), "\n", React.createElement(_components.h3, null, "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsum perspiciatis dicta"), "\n", React.createElement(_components.p, null, "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsum perspiciatis dicta, obcaecati, suscipit voluptatum voluptates sed ipsa saepe fugit quas beatae temporibus, atque voluptas pariatur dolore facere odit eos."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://octodex.github.com/images/yaktocat.png",
    alt: "Image of Yaktocat"
  })), "\n", React.createElement(_components.h3, null, "Second Title"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "lorem"), "\n", React.createElement(_components.li, null, "test"), "\n"), "\n", React.createElement("button", null, "TEST"), "\n", React.createElement(_components.p, null, "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius ipsum perspiciatis dicta, obcaecati, suscipit voluptatum voluptates sed ipsa saepe fugit quas beatae temporibus, atque voluptas pariatur dolore facere odit eos."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "import PieChart from '../components/PieChart';\nThis paragraph introduces a **data visualization**:\n<PieChart\n  title=\"Favourite foods\"\n  data={[\n    { label: 'Pizza', value: '30%' },\n    { label: 'Broccoli', value: '5%' },\n    { label: 'Haagen-Dasz', value: '65%' },\n  ]}\n/>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
