/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    h3: "h3",
    a: "a",
    pre: "pre",
    code: "code",
    blockquote: "blockquote",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Dalam artikel ini, anda akan diperlihatkan dua cara bagaimana mendeploy aplikasi react ke netlify."), "\n", React.createElement(_components.p, null, "Mungkin cara pertama ini akan terlihat cukup manual, kemudian cara yang kedua termasuk ", React.createElement(_components.em, null, "best practice"), " karena sudah termasuk ", React.createElement(_components.em, null, "continous deployments"), " dan update yang otomatis lewat\ngithub. Yeey, mungkin ini akan jadi luar biasa."), "\n", React.createElement(_components.h3, null, "Membuat repo React"), "\n", React.createElement(_components.p, null, "Untuk membuat project baru, kita akan menggunakan ", React.createElement(_components.a, {
    href: "https://create-react-app.dev/"
  }, "starter kit"), " resmi dari React."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "npx create-react-app my-react-app\ncd my-react-app\nnpm start\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Sebelumnya kalian harus memasang terlebih dahulu ", React.createElement(_components.code, null, "create-react-app"), " cli lewat perintah ", React.createElement(_components.code, null, "npm install -g create-react-app")), "\n"), "\n", React.createElement(_components.p, null, "Jika muncul tampilan berikut di terminal hal itu menandakan kalian sudah berhasil membuat project baru menggunakan ", React.createElement(_components.code, null, "create-react-app"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "Success! Created my-react-app at D:\\Software Dev\\my-react-app\nInside that directory, you can run several commands:\n\nyarn start\n...\n")), "\n", React.createElement(_components.p, null, "Kemudian coba jalankan perintah berikut untuk memulai proses development:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "cd my-react-app\nyarn start\n")), "\n", React.createElement(_components.p, null, "Silakan kalian ketikkan url berikut ", React.createElement(_components.code, null, "http://localhost:3000"), " di browser anda. Harusnya kalian sudah melihat tampilan berikut:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/muhrusdi/image/upload/v1623573070/Capture.png",
    alt: "Result"
  })), "\n", React.createElement(_components.p, null, "Sebelum kita deploy, silakan masuk di file ", React.createElement(_components.code, null, "src/App.js"), " untuk mengubah beberap kode, seperti pada gambar blok kode berikut:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import logo from './logo.svg';\nimport './App.css';\n\nfunction App() {\n  return (\n    <div className=\"App\">\n      <header className=\"App-header\">\n        <img src={logo} className=\"App-logo\" alt=\"logo\" />\n        // highlight-add-start\n        <p>\n          Belajar deploy aplikasi react ke netlify\n        </p>\n        // highlight-add-end\n        // highlight-remove-start\n        <p>\n          Edit <code>src/App.js</code> and save to reload.\n        </p>\n        // highlight-remove-end\n        <a\n          className=\"App-link\"\n          href=\"https://reactjs.org\"\n          target=\"_blank\"\n          rel=\"noopener noreferrer\"\n        >\n          Learn React\n        </a>\n      </header>\n    </div>\n  );\n}\n\nexport default App;\n\n")), "\n", React.createElement(_components.p, null, "Silakan save dan lihat hasilnya di browser."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/muhrusdi/image/upload/v1623573496/Capture2_2.png",
    alt: "Edit version"
  })), "\n", React.createElement(_components.p, null, "Yee, luar biasa!. Sekarang kita fokus tujuan utama artikel kita yaitu saat deploy ke netlify."), "\n", React.createElement(_components.h3, null, "Deploy manual"), "\n", React.createElement(_components.p, null, "Kita mulai dari cara yang paling mudah yaitu hanya drag and drop. Tetapi sebelum itu kalian harus punya ", React.createElement(_components.a, {
    href: "https://app.netlify.com/signup"
  }, "akun netlify"), " terlebih dahulu."), "\n", React.createElement(_components.p, null, "Setelah membuat akun netlify kita harus login, dan kemudian buka menu sites ", React.createElement(_components.code, null, "https://app.netlify.com/teams/{your_account}/sites"), ", silakan scroll kebagian paling bawa\ndan anda akan melihat bagian kosong yang mana kita dapat drag website kita kebagian tersebut:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/muhrusdi/image/upload/v1623574222/Capture3.png",
    alt: "Box"
  })), "\n", React.createElement(_components.p, null, "Sebelum itu melakukan hal diatas, kalian perlu melakukan build dengan menjalankan perintah berikut:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "yarn build\n")), "\n", React.createElement(_components.p, null, "Perintah ini akan membuat direktori ", React.createElement(_components.code, null, "build"), ", seprti yang terlihat di gambar berikut:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/muhrusdi/image/upload/v1623574449/Capture4.png",
    alt: "Folder"
  })), "\n", React.createElement(_components.p, null, "Kemudian drag and drop direktori ", React.createElement(_components.code, null, "build"), " kedalam bagian kosong dihalaman netlify. Tunggu beberapa detik, setelah itu harusnya aplikasi react anda telah terdepoloy 🎉."), "\n", React.createElement(_components.h3, null, "Deploy otomatis"), "\n", React.createElement(_components.p, null, "Dengan menggunakan cara ini, maka setiap perubahan terhadap kode kita yang ada di github akan membuat website terdeploy secara otomatis ke netlify."), "\n", React.createElement(_components.p, null, "Untuk melakukan ini, kita perlu menyimpan projek ke repositoi github. Kunjungi ", React.createElement(_components.a, {
    href: "https://github.com"
  }, "github"), " dan buat repositori baru. Untuk panduannya, kalian dapat mengikuti\ndi tautan berikut ", React.createElement(_components.a, {
    href: "https://docs.github.com/en/github/importing-your-projects-to-github/importing-source-code-to-github/adding-an-existing-project-to-github-using-the-command-line"
  }, "disini")), "\n", React.createElement(_components.p, null, "Jalankan perintah berikut untuk push kode kalian ke github:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "git push -u origin main\n")), "\n", React.createElement(_components.p, null, "Kemudian kembali ke halaman netlify dan mulai mengimport repositori kita dengan menekan tombol ", React.createElement(_components.code, null, "New site from Git"), ":"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/muhrusdi/image/upload/v1623637700/Capture5.png",
    alt: "new site"
  })), "\n", React.createElement(_components.p, null, "Setelah itu kita akan dihadapkan sebuah pilihan. Dalam kasus ini kita pilih github,\nkarena dilangka sebelumnya repositorinya di push ke github:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/muhrusdi/image/upload/v1623637700/Capture6.png",
    alt: "repo"
  })), "\n", React.createElement(_components.p, null, "Kemudian kalian bisa mencari nama projek yang ingin kalian deploy:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/muhrusdi/image/upload/v1623637700/Capture7.png",
    alt: "deploy"
  })), "\n", React.createElement(_components.p, null, "Pilih repositori yang ingin di deploy, kemudian tekan tombol deploy dan tunggu beberapa detik, setelah itu seharusnya kalian sudah\ndapat melihat website kalian sudah live."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
