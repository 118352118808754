/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Kita akan membuat program yang akan mengubah bilangan desimal menjadi bilangan biner\ndengan menggunakan recursive."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const base10ToString = (n) => {\n  let binaryString = ''\n  function base10ToStringHelper(n) {\n    if (n < 2) {\n      binaryString += n\n      return\n    } else {\n      base10ToStringHelper(Math.floor(n / 2))\n      base10ToStringHelper(n % 2)\n    }\n  }\n  base10ToStringHelper(n)\n  return binaryString\n}\n\nbase10ToString(1234) // 10011010010\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "TIme Complexity: : O(log2(n)) atau logartichmic"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
