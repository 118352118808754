/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    br: "br",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Beberapa hari yang lalu telah hadir ", React.createElement(_components.a, {
    href: "https://reactjs.org/blog/2021/06/08/the-plan-for-react-18.html"
  }, "React 18"), " dalam versi Alpha, ini adalah", React.createElement(_components.br), "\n", "berita menarik bagi para pengembang front end khususnya yang mengadopsi react dalam stack mereka. Rilis kali ini termasuk rilis yang besar namun tidak menyertakan fungsi baru"), "\n", React.createElement(_components.h3, null, "Apa yang baru?"), "\n", React.createElement(_components.p, null, "Jika kalian tertarik ingin mengetahui lebih dalam mengenai apa yang baru di versi ni, kalian dapat melihat secara langsung diskusi ", React.createElement(_components.a, {
    href: "https://github.com/reactwg/react-18/discussions"
  }, "di Github"), ".\nTim dibalik pengembangan React melakukan pekerjaannya dengan baik termasuk dalam menjelaskan hal secara detail dan aktif dalam menjawab pertanyaan."), "\n", React.createElement(_components.p, null, "Kesimpulan dari rilis ini adalah kalian dapat menggunakan React 18 tanpa harus mengubah kode aplikasi yang ada. Berangkat dari ", React.createElement(_components.a, {
    href: "https://github.com/reactwg/react-18/discussions/4"
  }, "pengenalan React 18"), "\nterdapat beberapa fitur-fitur yang diprioritaskan termasuk fitur yang sudah ada pada versi 16 dan ada juga fitur yang telah diperkenalkan sebelumnya namun masih dalam tahap preview."), "\n", React.createElement(_components.h3, null, "Suspense"), "\n", React.createElement(_components.p, null, "Fitur ini tersedia mulai dari React 16.6. Idenya adalah anda dapat menentukan kondisi awal saat menunggu beberapa kode dimuat."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Suspense fallback={<Spinner />}>\n  <ProfilePage />\n</Suspense>\n")), "\n", React.createElement(_components.p, null, "React 18 memang sangat memprioritaskan fitur ini. Buktinya, melihat dari diskusi ", React.createElement(_components.a, {
    href: "https://github.com/reactwg/react-18/discussions/22"
  }, "di Github"), ",\nfitur ini sebelumnya tidak mendukung di sisi server dan sekarang di versi 18 ini sudah mendukung walaupun ada tingkat dukungan yang berbeda tergantung pada API yang digunakan."), "\n", React.createElement(_components.h3, null, "Concurrent"), "\n", React.createElement(_components.p, null, "Ini termasuk fitur yang sering didapati dibeberapa diskusi seperti di twitter maupun di blog. Concurrent merupakan serangkaian fitur yang membantu aplikasi react tetap\nresponsif dan menyesuaikan dengan perangkat dan kecepatan jaringan."), "\n", React.createElement(_components.p, null, "Fitur ini masuk dalam daftar list yang dapat peningkatan di React 18"), "\n", React.createElement(_components.h3, null, "Server component"), "\n", React.createElement(_components.p, null, "Sebagian besar tim pengembang dibelakang React memprioritaskan fitur-fitur yang berhubungan dengan sisi server. Termasuk fitur ini, tahun lalu desember 2020, Dan Abramove\nmemperkenalkan ", React.createElement(_components.a, {
    href: "https://reactjs.org/blog/2020/12/21/data-fetching-with-react-server-components.html"
  }, "server component"), ", dalam video ini berbicara bagaimana menulis komponen react\ndi sisi server dan dari komponen tersebut dikirim ke sisi klien."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
