/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Algoritma yang digunakan untuk mengubah kumpulan dictionary menjadi flat."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const dict = {\n  key1: 2,\n  key2: 23,\n  nested: {\n    a: 34,\n    b: 24,\n    nested: {\n      c: 'qwerty',\n      d: 'asdf'\n    }\n  }\n}\n\nfunction flatDict(dictionary) {\n  let initDict = {}\n\n  function dictHelper(dict, propName) {\n    if (typeof dict !== 'object') {\n      initDict[propName] = dict\n      return\n    }\n    for (const prop in dict) {\n      // if (propName == '') {\n        dictHelper(dict[prop], prop)\n      // } else {\n      //   dictHelper(dict[prop], propName+'.'+prop)\n      // }\n    }\n  }\n\n  dictHelper(dictionary, '')\n  return initDict\n}\n\nflatDict(dict) // { key1: 2, key2: 23, a: 34, b: 24, c: 'qwerty', d: 'asdf' }\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "TIme Complexity: : O(log2(n)) atau logartichmic"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
