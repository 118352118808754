import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/src/data/blogs/_draft.mdx";
import React from "react";
import {Header} from "./index";
import {ArticleStyled} from "@/components/utils";
import SEO from "@/components/seo";
import MdxRender from "@/components/mdx-render";
import {Layout} from "@/containers/layout";
var DetailBlogMDX = function DetailBlogMDX(_ref) {
  var _data$frontmatter$fea;
  var pageContext = _ref.pageContext, children = _ref.children;
  var data = pageContext.data;
  return React.createElement(Layout, null, React.createElement("div", null, React.createElement(SEO, {
    title: data.frontmatter.title,
    description: data.frontmatter.abstract,
    image: (_data$frontmatter$fea = data.frontmatter.featuredImage) === null || _data$frontmatter$fea === void 0 ? void 0 : _data$frontmatter$fea.url
  }), React.createElement(Header, {
    title: data.frontmatter.seoTitle,
    description: data.frontmatter.abstract,
    date: data.frontmatter.publishedOn,
    featuredImage: data.frontmatter.featuredImage
  }), React.createElement("div", {
    className: "sm:pr-6 sm:pl-6 mx-auto max-w-2xl mt-24"
  }, React.createElement(ArticleStyled, {
    className: "prose prose-xl"
  }, React.createElement(MdxRender, {
    data: children
  })))));
};
DetailBlogMDX
export default function GatsbyMDXWrapper(props) {
  return React.createElement(DetailBlogMDX, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var query = "2499994864";
