/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Preview from "@shipless/play";
import {value1, value2, value3} from "@/components/utils/initialContent.ts";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    blockquote: "blockquote",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Dalam beberapa kasus kita perlu mengubah tampilan scrollbar di browser kita, seperti ketika\nscrollbar element yang berada dalam container atau yang biasa disebut nested scroll, atau dikondisi lain ketika\ningin membuat interface kita terlihat lebih konsisten ketika berada dibeberapa sistem operasi."), "\n", React.createElement(_components.p, null, "Berikut beberapa contoh untuk mengubah style scrollbar di browser menggunakan properti css prefix ", React.createElement(_components.code, null, "::webkit-scrollbar"), ":"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Custom scrollbar menggunakan CSS hanya mendukung di browser yang berbasis ", React.createElement(_components.code, null, "webkit"), " seperti Chrome/Safari."), "\n"), "\n", React.createElement(_components.h2, null, "Style #1"), "\n", React.createElement(Preview, {
    defaultValue: value1
  }), "\n", React.createElement(_components.h2, null, "Style #2"), "\n", React.createElement(Preview, {
    defaultValue: value2
  }), "\n", React.createElement(_components.h2, null, "Style #3"), "\n", React.createElement(Preview, {
    defaultValue: value3
  }), "\n", React.createElement(_components.p, null, "Silakan berkreasi dalam mengubah style scrollbar website kalian dengan memanfaatkan css properti ", React.createElement(_components.code, null, "::webkit-scrollbar"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
