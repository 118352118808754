exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-3-d-tsx": () => import("./../../../src/pages/3d/[...].tsx" /* webpackChunkName: "component---src-pages-3-d-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cheatsheet-index-tsx": () => import("./../../../src/pages/cheatsheet/index.tsx" /* webpackChunkName: "component---src-pages-cheatsheet-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-murottal-tsx": () => import("./../../../src/pages/murottal/[...].tsx" /* webpackChunkName: "component---src-pages-murottal-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-story-index-tsx": () => import("./../../../src/pages/story/index.tsx" /* webpackChunkName: "component---src-pages-story-index-tsx" */),
  "component---src-pages-twitter-tsx": () => import("./../../../src/pages/twitter/[...].tsx" /* webpackChunkName: "component---src-pages-twitter-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work/[...].tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-blogs-detail-index-tsx": () => import("./../../../src/templates/blogs/detail/index.tsx" /* webpackChunkName: "component---src-templates-blogs-detail-index-tsx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-1-tips-struktur-html-tailwindcss-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/1-tips-struktur-html-tailwindcss.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-1-tips-struktur-html-tailwindcss-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-2-membuat-blog-dengan-gatsbyjs-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/2-membuat-blog-dengan-gatsbyjs.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-2-membuat-blog-dengan-gatsbyjs-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-3-apa-yang-baru-react-18-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/3-apa-yang-baru-react-18.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-3-apa-yang-baru-react-18-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-4-deploy-react-netlify-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/4-deploy-react-netlify.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-4-deploy-react-netlify-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-5-introducing-astro-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/5-introducing-astro.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-5-introducing-astro-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-6-css-library-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/6-css-library.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-6-css-library-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-6-custom-scrollbar-css-index-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/6-custom-scrollbar-css/index.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-6-custom-scrollbar-css-index-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-draft-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/_draft.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-draft-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-intro-mdx": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/intro.mdx" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-intro-mdx" */),
  "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-second-md": () => import("./../../../src/templates/blogs/detail/mdx.tsx?__contentFilePath=/opt/buildhome/repo/src/data/blogs/second.md" /* webpackChunkName: "component---src-templates-blogs-detail-mdx-tsx-content-file-path-opt-buildhome-repo-src-data-blogs-second-md" */),
  "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-1-bilangan-prima-mdx": () => import("./../../../src/templates/cheatsheets/detail/index.tsx?__contentFilePath=/opt/buildhome/repo/src/data/cheatsheets/1-bilangan-prima.mdx" /* webpackChunkName: "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-1-bilangan-prima-mdx" */),
  "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-2-big-o-mdx": () => import("./../../../src/templates/cheatsheets/detail/index.tsx?__contentFilePath=/opt/buildhome/repo/src/data/cheatsheets/2-big-o.mdx" /* webpackChunkName: "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-2-big-o-mdx" */),
  "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-decima-to-binary-mdx": () => import("./../../../src/templates/cheatsheets/detail/index.tsx?__contentFilePath=/opt/buildhome/repo/src/data/cheatsheets/decima-to-binary.mdx" /* webpackChunkName: "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-decima-to-binary-mdx" */),
  "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-draft-copy-mdx": () => import("./../../../src/templates/cheatsheets/detail/index.tsx?__contentFilePath=/opt/buildhome/repo/src/data/cheatsheets/_draft copy.mdx" /* webpackChunkName: "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-draft-copy-mdx" */),
  "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-draft-mdx": () => import("./../../../src/templates/cheatsheets/detail/index.tsx?__contentFilePath=/opt/buildhome/repo/src/data/cheatsheets/_draft.mdx" /* webpackChunkName: "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-draft-mdx" */),
  "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-flat-dictionary-mdx": () => import("./../../../src/templates/cheatsheets/detail/index.tsx?__contentFilePath=/opt/buildhome/repo/src/data/cheatsheets/flat-dictionary.mdx" /* webpackChunkName: "component---src-templates-cheatsheets-detail-index-tsx-content-file-path-opt-buildhome-repo-src-data-cheatsheets-flat-dictionary-mdx" */)
}

