/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Bilangan prima adalah bilangan lebih dari 1 dan hanya bisa dibagi dengan angka 1 atau bilangan itu sendiri. Angka 2 adalah bilangan prima karena hanya bisa dibagi dengan angka 1 dan angka 2.\nAngka 8 bukanlah bilangan prima karena selain bisa dibagi dengan angka 1 dan 8, 8 juga bisa dibagi dengan angka 2 dan 4."), "\n", React.createElement(_components.p, null, "Untuk mengetes bilangan prima yaitu dengan mengiterate dari 2 ke n, check apakah hasil pembagian\nsama dengan 0."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const isPrime = (n) => {\n  if (n <= 1) {\n    return false\n  }\n\n  for (let i = 2; i < n; i++) {\n    if (n % i == 0) {\n      return false\n    }\n  }\n\n  return true\n}\n\nisPrime(19) // true\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "TIme Complexity: O(n)"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
