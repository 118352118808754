import styled from "styled-components"

export const EditorStyled = styled.div``

export const TabBarStyled = styled.div`
  button {
    &:focus {
      outline: none;
    }
  }
`

export const SplitBarStyled = styled.div``
