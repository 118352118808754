import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/src/data/cheatsheets/flat-dictionary.mdx";
import React from "react";
import {Link} from "gatsby";
import {BsChevronRight} from "react-icons/bs";
import CheatsheetContainer from "@/components/cheatsheet/container";
import {Layout} from "@/containers/layout";
var CheatSheet = function CheatSheet(_ref) {
  var _dataServe$cheatsheet, _dataServe$cheatsheet2;
  var pageContext = _ref.pageContext, children = _ref.children, dataServe = _ref.data;
  var data = pageContext.data, slug = pageContext.slug;
  return React.createElement(Layout, null, React.createElement("div", null, React.createElement("ul", {
    className: "flex items-center -mx-1"
  }, React.createElement("li", {
    className: "px-1"
  }, React.createElement(Link, {
    to: "/",
    className: "w-8 h-8 rounded-md bg-blueGray-900 flex items-center justify-center"
  }, React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("rect", {
    x: "7",
    y: "7",
    width: "4",
    height: "4",
    rx: "1.2",
    fill: "url(#paint0_linear-710411)"
  }), React.createElement("rect", {
    x: "7",
    y: "13",
    width: "4",
    height: "4",
    rx: "1.2",
    fill: "url(#paint1_linear-294494)"
  }), React.createElement("rect", {
    x: "13",
    y: "7",
    width: "4",
    height: "4",
    rx: "1.2",
    fill: "url(#paint2_linear-508078)"
  }), React.createElement("rect", {
    x: "13",
    y: "13",
    width: "4",
    height: "4",
    rx: "1.2",
    fill: "url(#paint3_linear-004582)"
  }), React.createElement("defs", null, React.createElement("linearGradient", {
    id: "paint0_linear-710411",
    x1: "6.5",
    y1: "7",
    x2: "11",
    y2: "11.5",
    gradientUnits: "userSpaceOnUse"
  }, React.createElement("stop", {
    stopColor: "#34D399"
  }), React.createElement("stop", {
    offset: "1",
    stopColor: "#059669"
  })), React.createElement("linearGradient", {
    id: "paint1_linear-294494",
    x1: "6.5",
    y1: "13",
    x2: "11",
    y2: "17.5",
    gradientUnits: "userSpaceOnUse"
  }, React.createElement("stop", {
    stopColor: "#FBBF24"
  }), React.createElement("stop", {
    offset: "1",
    stopColor: "#D97706"
  })), React.createElement("linearGradient", {
    id: "paint2_linear-508078",
    x1: "12.5",
    y1: "7",
    x2: "17",
    y2: "11.5",
    gradientUnits: "userSpaceOnUse"
  }, React.createElement("stop", {
    stopColor: "#F472B6"
  }), React.createElement("stop", {
    offset: "1",
    stopColor: "#7C3AED"
  })), React.createElement("linearGradient", {
    id: "paint3_linear-004582",
    x1: "12.5",
    y1: "13",
    x2: "17",
    y2: "17.5",
    gradientUnits: "userSpaceOnUse"
  }, React.createElement("stop", {
    stopColor: "#F472B6"
  }), React.createElement("stop", {
    offset: "1",
    stopColor: "#7C3AED"
  })))))), React.createElement("li", {
    className: "px-1"
  }, React.createElement(BsChevronRight, {
    size: 12
  })), React.createElement("li", {
    className: "px-1"
  }, React.createElement(Link, {
    to: "/cheatsheet"
  }, "Cheatsheet")), React.createElement("li", {
    className: "px-1"
  }, React.createElement(BsChevronRight, {
    size: 12
  })), React.createElement("li", {
    className: "px-1"
  }, React.createElement("span", null, dataServe === null || dataServe === void 0 ? void 0 : (_dataServe$cheatsheet = dataServe.cheatsheetMdx) === null || _dataServe$cheatsheet === void 0 ? void 0 : _dataServe$cheatsheet.frontmatter.title))), React.createElement("div", {
    className: "mt-6"
  }, React.createElement(CheatsheetContainer, {
    data: children,
    frontmatter: dataServe === null || dataServe === void 0 ? void 0 : (_dataServe$cheatsheet2 = dataServe.cheatsheetMdx) === null || _dataServe$cheatsheet2 === void 0 ? void 0 : _dataServe$cheatsheet2.frontmatter,
    dataCheatsheets: dataServe === null || dataServe === void 0 ? void 0 : dataServe.allCheatsheets,
    slug: slug
  }))));
};
CheatSheet
export default function GatsbyMDXWrapper(props) {
  return React.createElement(CheatSheet, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var query = "3697131463";
