import styled from "styled-components"

export const EditorStyled = styled.div`
  position: relative;
  /* &:before {
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
    content: " ";
    position: absolute;
    left: -20px;
    right: -20px;
    bottom: -20px;
    top: 40px;
    z-index: 0;
    border-radius: 16px;
  } */
`
